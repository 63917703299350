import React from 'react'

import { Global } from '@creditas/global'

class App extends React.Component {
  componentDidCatch() {}

  render() {
    return <Global />
  }
}

export { App }
